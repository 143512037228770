type Config = {
    app_id: string;
    environments: {
        [key: string]: {
            base: string;
            gateway: string;
            cdn?: string;
        };
    };
};

const config: Config = {
    app_id: "admin",
    environments: {
        dev: {
            gateway: "/api",
            base: "/",
            cdn: "http://8.217.178.131:10006/getFile?path=",
        },
        test: {
            gateway: "http://8.217.178.131:10006",
            base: "/",
            cdn: "http://8.217.178.131:10006/getFile?path=",
        },
        prod: {
            gateway: "https://admin.api.668098.com",
            base: "/",
            cdn: "https://admin.api.668098.com/getFile?path=",
        },
    },
};

export const getEnvironment = () => {
    //@ts-ignore
    return config.environments[process.env.APP_ENV];
};

export default config;
